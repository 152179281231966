import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import AuthGuard from "../pages/auth/AuthGuard";
import DashboardLayout from "../layout/DashboardLayout";
import SimpleLayout from "../layout/SimpleLayout";
import {
  SignUpPage,
  LoginPage,
  ResetPasswordPage,
  DashBoardPage,
  MessagePage,
  ForgetPasswordPage,
  OtpVerificationPage,
  PromoCodePage,
  EmailStatusPage,
  ForgetPasswordResetSuccess,
  ForgetPasswordEmailPage,
  SettingsPage,
  VideoCallPage,
  SchedulePage,
  MeetingJoinPage,
  ParticipantsPage,
  FaqPage,
  DocumentsPage,
  ContactPage,
  NotificationPage,
  GuestLoginPage,
  MeetingNotStartPage,
  GuestVideoCallApp,
  CreateMeetingPage,
  CallHistoryPage,
  SocialMediaSignUpSuccessPage,
  AdminDashboardpage,
  ManageSubscriptionPage, 
  ChangePasswordPage,
  EditProfilePage, OrderHistorySection,NotificationSection,SubscriptionCard
} from "./elements";
import { PATH_AFTER_LOGIN } from "../utils/Config-globals";

export default function Router() {
  return useRoutes([
    { path: "/", element: <Navigate to="/auth/login" /> },
    {
      path: "auth",
      children: [
        { path: "login", element: <LoginPage /> },
        { path: "sign-up", element: <SignUpPage /> },
        { path: "reset-password", element: <ResetPasswordPage /> },
        { path: "otp-verification", element: <OtpVerificationPage /> },
        { path: "forgot-password", element: <ForgetPasswordPage /> },
        { path: "promocode", element: <PromoCodePage /> },
        { path: "email-sucess", element: <EmailStatusPage /> },
        { path: "password-sucess", element: <ForgetPasswordEmailPage /> },
        { path: "password-reset-success", element: <ForgetPasswordResetSuccess /> },
        { path: "guest-login", element: <GuestLoginPage /> }, // Existing route for guest login
        { 
          path: "guest-login/join", 
          element: <GuestLoginPage />  // <-- New route for guest login with join
        },
        { path: "create-meeting/:id", element: <CreateMeetingPage /> },
        { path: "social-media-signup", element: <SocialMediaSignUpSuccessPage /> },
      ],
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "overview", element: <DashBoardPage /> },
        { path: "admindashboard", element: <AdminDashboardpage /> },
        { path: "messages", element: <MessagePage /> },
        { path: "settings", element: <SettingsPage /> },
        { path: "schedule", element: <SchedulePage /> },
        { path: "subscription", element: <SubscriptionCard /> },
        { path: "contacts", element: <ContactPage /> },
        { path: "notifications", element: <NotificationPage /> },
        { path: "meeting-join", element: <MeetingJoinPage /> },
        { path: "participants", element: <ParticipantsPage /> },
        { path: "support", element: <FaqPage /> },
        { path: "documents", element: <DocumentsPage /> },
        {
          path: "settings",
          element: <SettingsPage />,
          children: [
            { path: "manage-subscription", element: <ManageSubscriptionPage /> },  // Add child routes for sub-pages
            { path: "edit-profile", element: <EditProfilePage /> },
            { path: "change-password", element: <ChangePasswordPage /> }, 
            { path: "order-history", element: <OrderHistorySection /> }, 
            { path: "notification", element: <NotificationSection /> },
          ],
        },
        {
          path: "call",
          children: [
            { element: <CallHistoryPage />, index: true },
            { path: "meet", element: <VideoCallPage /> },
            {
              path: "join/:meetingId",
              element: <MeetingJoinPage />,
            },
            {
              path: "join/",
              element: <MeetingJoinPage />, // Render the MeetingJoinPage directly
            },
          ],
        },
      ],
    },
    {
      path: "guest",
      element: <SimpleLayout />, // Layout for guest pages
      children: [
        { path: "join/:quickCallId", element: <MeetingJoinPage /> }, 
        { path: "meet", element: <GuestVideoCallApp /> },
        { path: "not-start", element: <MeetingNotStartPage /> }, 
      ],
    },  
  ]);
}
