import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, IconButton } from "@mui/material"; // Import IconButton
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import MailIcon from "@mui/icons-material/Mail";
import Lock from "@mui/icons-material/Lock"; // Import Lock icons
import LockOpen from "@mui/icons-material/LockOpen";
import { Button, Divider, FormControlLabel, Grid, Typography, useTheme } from "@mui/material";
import { Field,Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CustomTextField } from "../../components/TextField/TextField";
import { CustomButton } from "../../components/Butons/CustomButton";
import GoogleIcon from "@mui/icons-material/Google";
import {
  CustomSubtitle2,
  CustomTypography4,
} from "../../components/Typography/Typography";
import { styled } from "@mui/material/styles";
import { PRIMARY } from "../../theme/Palette";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/slices/AuthSlice";
import { BpRadio } from "../../components/Radio/CustomRadio";

const CustomDivider = styled(Divider)({
  "&.MuiDivider-root::before, &.MuiDivider-root::after": {
    width: "100%",
    borderTop: "1px solid var(--ffffff-50, rgba(255, 255, 255, 0.50));",
    content: "''",
  },
});

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.info,
}));

export default function LoginForm() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 

  useEffect(() => {
    const token = localStorage.getItem("authToken") || sessionStorage.getItem("authToken");
    if (token) {
      // Redirect to the dashboard if token exists
      navigate("/dashboard/overview", { replace: true });
    }
  }, [navigate]);
  
  
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe:false

    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      setErrorMessage("");
      setLoading(true);
    
      if (!values.email || !values.password) {
        setErrorMessage("Email and password are required.");
        setLoading(false);
        return;
      }
    
      try {
        const res = await dispatch(loginUser(values));
        if (loginUser.fulfilled.match(res)) {
          const { status, data } = res.payload;
          const token = data?.data?.access_token;
    
          if (status === 200 && token) {
            if (values.rememberMe) {
              localStorage.setItem("authToken", token);
            } else {
              sessionStorage.setItem("authToken", token);
            }
    
            navigate("/dashboard/overview", { replace: true });
            resetForm();
          } else {
            setErrorMessage("Authentication failed. Token not provided.");
          }
        } else if (loginUser.rejected.match(res)) {
          const error = res?.payload?.detail;
          setErrorMessage(error);
          setTimeout(() => {
            setErrorMessage("");
          }, 10000);
        }
      } catch (error) {
        console.error("Login error:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");
        setTimeout(() => {
          setErrorMessage("");
        }, 10000);
      } finally {
        setLoading(false);
      }
    },         

  });

  const { errors, touched,values, handleSubmit, handleChange ,setFieldValue} = formik;

  const handleNavigate = () => {
    navigate("/auth/forgot-password");
  };

  const handleFieldChange = (e) => {
    const { name } = e.target;
    if (errors[name]) {
      formik.setFieldTouched(name, false, false);
      formik.setFieldError(name, "");
    }
    if (errorMessage) {
      setErrorMessage("");
    }
    handleChange(e);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* Email Input Field */}
          <Grid item xs={12} md={12}>
            <InputLabel
              htmlFor="input-with-icon-textfield"
              sx={{
                color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                fontSize: "14px",
                fontFamily: "Lato",
                fontWeight: "400",
                lineHeight: "21px",
              }}
            >
              Email
            </InputLabel>
            <CustomTextField
              fullWidth
              id="input-with-icon-textfield"
              name="email"
              placeholder="Type your email address"
              autoComplete="off"
              onChange={handleFieldChange}
              InputProps={{
                style: {
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontStyle: "normal",
          
                  lineHeight: "19.2px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <MailIcon
                      sx={{
                        color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                        fontSize: "18px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>

          {/* Password Input Field */}
          <Grid item xs={12} md={12}>
            <InputLabel
              htmlFor="input-with-icon-textfield"
              sx={{
                color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                fontSize: "14px",
                fontFamily: "Lato",
                fontWeight: "400",
                lineHeight: "21px",
              }}
            >
              Password
            </InputLabel>
            <CustomTextField
              fullWidth
              id="input-with-icon-textfield"
              type={showPassword ? "text" : "password"} // Toggle type based on state
              name="password"
              autoComplete="off"
              placeholder="Start typing ..."
              onChange={handleFieldChange}
              InputProps={{
                style: {
                  fontFamily: "Lato",
                 
             
                  fontSize: "16px",
                  lineHeight: "19.2px",
                },
                
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end" sx={{
                        color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                        fontSize: "18px",
                        
                      }}
                    >
                      {showPassword ? <LockOpen /> : <Lock />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="standard"
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Grid>

          {/* Error Message */}
          {errorMessage && (
            <Grid item xs={12}>
              <Typography
                color="error"
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                {errorMessage}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                  control={
                    <Field
                      as={BpRadio}
                      type="checkbox" //radio to checkbox
                      name="rememberMe"
                      checked={values.rememberMe}
                      onChange={() =>
                        setFieldValue(
                          "rememberMe",
                          !values.rememberMe
                        )
                      }
                    />
                  }
                   label="Remember me"
                  componentsProps={{
                    typography: {
                      sx: {
                        fontSize: "14px", // Adjust the font size here
                        fontFamily:"Lato",
                        fontWeight: 600, // Bold font
                        color: "rgba(128, 131, 163, 1)", // Text color
                        lineHeight: "21px", // Line height
                      },
                    },
                  }}
                />
              <CustomSubtitle2 sx={{ cursor: "pointer" }} onClick={handleNavigate}>
                Recover Password
              </CustomSubtitle2>
            </Box>
          </Grid>

          {/* Sign In Button with Loading circlw when we click sigin */}
          <Grid item xs={12} md={12}>

            <StyledButton
              variant="contained"
              sx={{
                height: "50px",
                borderRadius: "10px",
                background: theme.palette.primary.info, // Keep the same background color even when loading
                color: theme.palette.primary.white,
                textTransform: "none",
                "&:hover": {
                  background: "#007CD3", // Apply hover color only when not loading
                  boxShadow: "none",
                },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              fullWidth
              type="submit"
            //disabled={loading} // Disable button while loading//commented for keep the color same when  click button
            >
              {loading ? (
                <CircularProgress
                  size={20} //  for Adjust the size of the loader
                  sx={{ color: "white" }}
                />
              ) : (
                <CustomTypography4>Sign In</CustomTypography4>
              )}
            </StyledButton>



          </Grid>

          {/* Divider */}
          <Grid item xs={12} md={12} my={3}>
            <Box sx={{ paddingInline: "120px" }}>
              <CustomDivider>
                <CustomSubtitle2 sx={{ color: "rgba(128, 131, 163, 1)" }}>
                  Or
                </CustomSubtitle2>
              </CustomDivider>
            </Box>
          </Grid>

          {/* Sign Up with Google */}
          <Grid item xs={12} md={12} mt={2}>
            <CustomButton
              variant="contained"
              startIcon={
                <GoogleIcon sx={{ margin: "auto", color: "rgba(128, 131, 163, 1)" }} />
              }
              sx={{
                height: "50px",
                justifyContent: "flex-start",
                display: "flex",
                color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                "&:hover": {
                  background: "#434647",
                  boxShadow: "none",
                },
              }}
              fullWidth
              type="button"
            >
              <Box
                sx={{
                  textAlign: "left",
                  flex: 1,
                  marginLeft: "10px",
                  borderLeft: `1px solid ${PRIMARY.dark}`,
                  paddingLeft: "15px",
                  color: theme.palette.primary.white,
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }} onClick={() => navigate("/auth/promocode")} 
              >
                <CustomTypography4> Sign Up with Google</CustomTypography4>
              </Box>
            </CustomButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
