import React, { useState } from "react";
import {
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogContent,
  Tabs,
  Tab,
  Box,
  TextField,
  InputAdornment
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import ClearIcon from "@mui/icons-material/Clear";
import PropTypes from "prop-types";

const users = [
  { id: 1, name: "John Doe", role: "Developer", avatar: "https://i.pravatar.cc/300" },
  { id: 2, name: "Jane Smith", role: "Sales Manager", avatar: "https://i.pravatar.cc/301" }
];

const chat = [
  { id: 1, message: "John Doe will make the call", sender: "James", role: "Developer Team", avatar: "https://i.pravatar.cc/302" },
  { id: 2, message: "John Doe shared a file", sender: "James", role: "Blake", avatar: "https://i.pravatar.cc/303" }
];

const documents = [
  { id: 1, name: "John-Doe-report.pdf", sharedBy: "Blake", avatar: "https://i.pravatar.cc/304" },
  { id: 2, name: "John-Doe-resume.doc", sharedBy: "John", avatar: "https://i.pravatar.cc/305" }
];

const ViewAllModal = ({ open, handleClose }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleClearSearch = () => {
    setSearchValue("");
  };

  // Prevent modal close when clicking inside the modal
  const handleDialogClick = (event) => {
    event.stopPropagation(); // Stop the click event from propagating to the Dialog backdrop
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
            background: "var(--1-a-1-c-1-d-primary, #1A1C1D)",
          color: "#fff",
          padding: "16px 16px 0",
          borderRadius: "10px",
        },
      }}
    >
      {/* Wrap the content in a Box that stops propagation */}
      <Box onClick={handleDialogClick}>
        <DialogContent sx={{paddingBottom:"0"}}>
          {/* Search Field with Clear Icon */}
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <ClearIcon sx={{ color: "#fff" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              mb: 2,
              borderBottom:"1px solid #fff",
              input: { color: "#fff" },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none", // Remove the border of the search field
              },
            }}
          />

          {/* Tabs with Active Blue Border Bottom */}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Search tabs"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#007bff", // Blue underline for the active tab
              },
            }}
            sx={{
              "& .MuiTab-root": {
                color: "#FFFFFF80",
              }, // Inactive tab color
              "& .Mui-selected": {
                color: "#FFFFFF80",
              }, // Keep text color same for active tabs
            }}
          >
            <Tab label="All" sx={{textTransform:"capitalize"}} />
            <Tab label="Contacts"sx={{textTransform:"capitalize"}}  />
            <Tab label="Chats" sx={{textTransform:"capitalize"}} />
            <Tab label="Files"sx={{textTransform:"capitalize"}}  />
          </Tabs>
          
          {/* People Section */}
          <Box sx={{ backgroundColor: "#222526", mt: 2 }}>
            <Typography variant="subtitle1" color="textSecondary" sx={{ fontFamily: "Lato", fontSize: "14px", fontWeight: "700", color: "white", paddingLeft: "16px" }}>
              People
            </Typography>
            <List>
              {users.map((person) => (
                <ListItem key={person.id}>
                  <ListItemAvatar>
                    <Avatar src={person.avatar} />
                  </ListItemAvatar>
                  <ListItemText 
                    primary={person.name} 
                    secondary={person.role} 
                    primaryTypographyProps={{
                      sx: { 
                        fontFamily: "Lato", 
                        fontSize: "14px", 
                        fontWeight: "700" 
                      }
                    }}
                    secondaryTypographyProps={{
                      sx: {
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#FFFFFF80"
                      }
                    }}
                  />
                </ListItem>
              ))}
            </List>

            {/* Chat Section */}
            <Typography variant="subtitle1" color="textSecondary" sx={{ fontFamily: "Lato", fontSize: "14px", fontWeight: "700", color: "white", paddingLeft: "16px" }}>
              Chat
            </Typography>
            <List>
              {chat.map((item) => (
                <ListItem key={item.id}>
                  <ListItemAvatar>
                    <Avatar src={item.avatar} />
                  </ListItemAvatar>
                  <ListItemText 
                    primary={`${item.sender}: ${item.message}`} 
                    secondary={item.role}
                    primaryTypographyProps={{
                      sx: { 
                        fontFamily: "Lato", 
                        fontSize: "14px", 
                        fontWeight: "700" 
                      }
                    }}
                    secondaryTypographyProps={{
                      sx: {
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#FFFFFF80"
                      }
                    }}
                  />
                </ListItem>
              ))}
            </List>

            {/* Documents Section */}
            <Typography variant="subtitle1" color="textSecondary" sx={{ fontFamily: "Lato", fontSize: "14px", fontWeight: "700", color: "white", paddingLeft: "16px" }}>
              Documents
            </Typography>
            <List>
              {documents.map((doc) => (
                <ListItem key={doc.id}>
                  <ListItemAvatar>
                    <Avatar src={doc.avatar} />
                  </ListItemAvatar>
                  <ListItemText 
                    primary={doc.name} 
                    secondary={`Shared by ${doc.sharedBy}`} 
                    primaryTypographyProps={{
                      sx: { 
                        fontFamily: "Lato", 
                        fontSize: "14px", 
                        fontWeight: "700" 
                      }
                    }}
                    secondaryTypographyProps={{
                      sx: {
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#FFFFFF80"
                      }
                    }}
                  />
                  <ListItemSecondaryAction onClose={handleClose}>
                    <IconButton sx={{
                      border: "1px solid rgba(255, 255, 255, 0.2)",
                      borderRadius: "8px"
                    }}>
                      <DownloadIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ViewAllModal;

ViewAllModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
